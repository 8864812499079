<template>
  <div>
    <v-container>
      <div class="d-flex align-center">
        <v-img
          alt="Золотое сечение"
          contain
          src="@/assets/logo-circle.png"
          max-height="230"
        />
      </div>
      <p class="text-h5 mt-5 text-center font-weight-light">
        Заявка на конкурсный отбор по программам Фонда «Золотое сечение»
      </p>
      <v-btn
        fixed 
        right 
        bottom 
        large 
        fab
        color="#d7b56d"
        elevation="2"
        mode="out-in"  
        :to="{name: 'HowTo', params: { prev: 'Registration'}}"
        active-class="btn-fab"
      >
        <v-icon color="#fff">mdi-help</v-icon>
      </v-btn>
      <v-row class="mt-4" justify="center" align="center">
        <v-card
          tile
          :class="'px-' + horizontalPaddingValue + ' py-15'"
          max-width="1600"
        >
          <p :class="'px-' + horizontalPaddingValue">
            Подать онлайн-заявку на обучение, освоение модуля программы спортивной подготовки, участие в мероприятии 
            могут физические лица, которые соответствуют критериям отбора по выбранной программе.
          </p>
          <p :class="'px-' + horizontalPaddingValue">
            Просим вас заполнить заявку, указав необходимые данные. В случае
            положительной оценки заявки экспертами вы получите по электронной
            почте приглашение принять участие в программе Фонда.
          </p>
          <p :class="'px-' + horizontalPaddingValue">
            Для получения справочной информации, пожалуйста, напишите на электронную почту:
          </p>
          <div :class="'px-' + horizontalPaddingValue + ' pb-5'">
            <span class="font-weight-medium">Email: </span>
            <a class="font-weight-light" href="mailto:contact@zsfond.ru">contact@zsfond.ru</a>
          </div>
          <v-form class="mt-4" v-model="isValid">
            <p class="text-h6 font-weight-light mt-3">Ученик</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.lastName"
                  :rules="[rules.required, rules.maxSymbFullName]"
                  label="Фамилия"
                  hint="Введите фамилию"
                  color="#af955c"
                  dense
                  :error="!studentInfo.lastName"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.firstName"
                  :rules="[rules.required, rules.maxSymbFullName]"
                  label="Имя"
                  hint="Введите имя"
                  color="#af955c"
                  dense
                  :error="!studentInfo.firstName"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="5" xl="4">
                <v-text-field
                  :rules="[rules.maxSymbMiddleName]"
                  v-model="studentInfo.middleName"
                  label="Отчество"
                  hint="Введите отчество"
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="studentInfo.birthdayDate"
                  label="Дата рождения"
                  hint="Укажите дату рождения"
                  color="#af955c"
                  dense
                  type="date"
                  min="1900-01-01" 
                  max="2200-12-31"
                  :error="!studentInfo.birthdayDate"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="2" xl="2">
                <v-select
                  v-model="studentInfo.gender"
                  :items="genders"
                  :rules="[rules.required]"
                  label="Пол"
                  hint="Укажите пол"
                  color="#af955c"
                  dense
                  :error="!studentInfo.gender"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="5" xl="5">
                <v-text-field
                  v-model="studentInfo.snils"
                  label="СНИЛС"
                  hint="Укажите числа СНИЛС без пробелов"
                  color="#af955c"
                  v-mask="'###-###-### ##'"
                  dense
                  :rules="[rules.snils]"
                  :error="!studentInfo.snils"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row  class="mt-4" justify="center" align="center">
              
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Контактная информация</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="4" lg="3" xl="3">
                <v-text-field @copy.prevent @paste.prevent
                  v-model="studentInfo.contacts.email"
                  :rules="[
                    rules.required, 
                    rules.email,
                    rules.emailCorrect,
                  ]"
                  label="E-mail"
                  hint="Введите e-mail адрес"
                  color="#af955c"
                  dense
                  :error="!studentInfo.contacts.email"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="4" lg="3" xl="3">
                <v-text-field @copy.prevent @paste.prevent
                  v-model="emailRetry"
                  :rules="[
                    rules.required, 
                    rules.email,
                    rules.emailMatch(
                      studentInfo.contacts.email,
                      emailRetry
                    ),
                  ]"
                  label="Подтверждение E-mail"
                  hint="Введите e-mail адрес еще раз"
                  color="#af955c"
                  dense
                  :error="!emailRetry"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="4" lg="4" xl="2">
                <v-text-field
                  v-model="studentInfo.contacts.phone"
                  :rules="[rules.required, rules.phone]"
                  label="Телефон"
                  hint="Введите номер телефона"
                  v-mask="'+7(###)###-##-##'"
                  color="#af955c"
                  dense
                  type="phone"
                  :error="!studentInfo.contacts.phone || 
                  studentInfo.contacts.phone === '+7('"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="10" xl="8">
                <v-autocomplete
                  v-model="studentInfo.contacts.address"
                  label="Адрес"
                  hint="Введите адрес"
                  :loading="isLoading"
                  :items="addressItems"
                  item-text="fullAddress"
                  item-value="id"
                  no-filter
                  :search-input.sync="addressSearch"
                  hide-no-data
                  hide-details
                  :rules="[rules.required]"
                  :error="!studentInfo.contacts.address"
                  required
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-autocomplete>
              </v-col>
            </v-row>
           <v-row class="mt-4" justify="center" align="center">
            <v-col cols="12" sm="10" md="4" lg="3" xl="3">
                <v-text-field
                  v-model="studentInfo.contacts.house"
                  :rules="[rules.required, rules.maxSymbHouse]"
                  label="Номер дома"
                  placeholder="Пример: 2 или 5А"
                  hint="Введите номер дома"
                  color="#af955c"
                  :error="!studentInfo.contacts.house"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="4" lg="3" xl="3">
                <v-text-field
                  v-model="studentInfo.contacts.building"
                  :rules="[rules.maxSymbolBuilding]"
                  label="Корпус"
                  placeholder="Пример: 7 или 3, лит. А"
                  hint="Введите корпус/строение (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="4" lg="4" xl="2">
                <v-text-field
                  v-model="studentInfo.contacts.flat"
                  label="Квартира"
                  placeholder="Пример: 13 или 157"
                  :rules="[rules.maxSymbolFlat]"
                  hint="Введите номер квартиры (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Образование</p>
            <v-divider></v-divider>

            <div v-if="isGeneralEducationVisible">
              <p class="text-overline mt-3 ml-6">Общее</p>
              <v-divider inset></v-divider>
              <v-row class="mt-4" justify="center" align="center">
                <v-col cols="12" sm="10" md="12" lg="10" xl="8">
                  <v-text-field
                    v-model="studentInfo.education.general.name"
                    label="Наименование учреждения"
                    hint="Введите название учреждения"
                    color="#af955c"
                    dense
                    :rules="isGeneralEducationVisible ? [rules.required] : []"
                    :required="isGeneralEducationVisible"
                    :error="
                      !studentInfo.education.general.name &&
                      isGeneralEducationVisible
                    "
                    :disabled="isRegistrationSuccess"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                  <v-select
                    v-model="studentInfo.education.general.type"
                    label="Тип"
                    hint="Укажите тип учреждения"
                    color="#af955c"
                    dense
                    :items="generalEducationTypes"
                    item-text="name"
                    item-value="id"
                    :rules="isGeneralEducationVisible ? [rules.required] : []"
                    :error="
                      !studentInfo.education.general.type &&
                      isGeneralEducationVisible
                    "
                    :required="isGeneralEducationVisible"
                    :disabled="isRegistrationSuccess"
                  ></v-select>
                </v-col>
                <v-col cols="7" sm="3" md="4" lg="3" xl="2">
                  <v-select
                    v-model="studentInfo.education.general.language"
                    label="Иностранный язык"
                    hint="Укажите ин. язык"
                    color="#af955c"
                    dense
                    :items="foreignLanguages"
                    item-text="name"
                    item-value="id"
                    :disabled="isRegistrationSuccess"
                  ></v-select>
                </v-col>
                <v-col cols="5" sm="2" md="2" lg="1" xl="1">
                  <v-select
                    v-model="studentInfo.education.general.grade"
                    label="Класс"
                    hint="Укажите класс"
                    color="#af955c"
                    dense
                    :items="grades"
                    :rules="isGeneralEducationVisible ? [rules.required] : []"
                    :required="isGeneralEducationVisible"
                    :error="
                      !studentInfo.education.general.grade &&
                      isGeneralEducationVisible
                    "
                    :disabled="isRegistrationSuccess"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="5" md="4" lg="3" xl="3">
                  <v-checkbox
                    v-model="studentInfo.education.general.remote"
                    label="Самостоятельное обучение"
                    color="#af955c"
                    :disabled="isRegistrationSuccess"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
            <div v-if="isProfessionalEducationVisible">
              <p class="text-overline mt-3 ml-6">Профессиональное</p>
              <v-divider inset></v-divider>
              <v-row class="mt-4" justify="center" align="center">
                <v-col cols="12" sm="10" md="12" lg="10" xl="8">
                  <v-text-field
                    v-model="studentInfo.education.professional.name"
                    label="Наименование учреждения"
                    hint="Введите название учреждения"
                    color="#af955c"
                    dense
                    
                    :rules="studentInfo.education.professional.type 
                    || studentInfo.education.professional.grade ? [rules.required] : []"
                    :required="studentInfo.education.professional.type 
                    || studentInfo.education.professional.grade"
                    :error="!studentInfo.education.professional.name 
                    && (studentInfo.education.professional.type 
                    || studentInfo.education.professional.grade)"
                    
                    :disabled="isRegistrationSuccess"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                  <v-select
                    v-model="studentInfo.education.professional.type"
                    label="Тип"
                    hint="Укажите тип учреждения"
                    color="#af955c"
                    dense
                    clearable
                    :items="professionalEducationTypes"
                    item-text="name"
                    item-value="id"
                    
                    :rules="studentInfo.education.professional.name || 
                    studentInfo.education.professional.grade? [rules.required] : []"
                    :required="studentInfo.education.professional.name || 
                    studentInfo.education.professional.grade"
                    :error="!studentInfo.education.professional.type && 
                    (!!studentInfo.education.professional.name || 
                    studentInfo.education.professional.grade)"
                   
                    :disabled="isRegistrationSuccess"
                  ></v-select>
                </v-col>
                <v-col cols="5" sm="2" md="2" lg="1" xl="2">
                  <v-select
                    v-model="studentInfo.education.professional.grade"
                    label="Год"
                    hint="Укажите год обучения"
                    color="#af955c"
                    dense
                    clearable
                    :items="grades"
                    
                    :rules="studentInfo.education.professional.name || 
                    studentInfo.education.professional.type? [rules.required] : []"
                    :required="studentInfo.education.professional.name || 
                    studentInfo.education.professional.type"
                    :error="!studentInfo.education.professional.grade && 
                    (!!studentInfo.education.professional.name || 
                    studentInfo.education.professional.type)"
                    
                    :disabled="isRegistrationSuccess"
                  ></v-select>
                </v-col>
              </v-row>
            </div>

            <v-row class="mt-4" justify="center" align="center">
              <v-btn
                :color="isProfessionalEducationVisible ? '#e31e25' : '#7690c9'"
                class="ma-2"
                text
                dark
                @click="professionalEducationButton"
                :disabled="isRegistrationSuccess"
              >
                <v-icon left dark>{{
                  isProfessionalEducationVisible ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                <span v-html="isProfessionalEducationVisible ? 'Удалить проф. образование' : 'Добавить проф. образование <br> (Необязательно)'"></span>
              </v-btn>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Родители</p>
            <v-divider></v-divider>

            <p v-if="isParentTwoVisible" class="text-overline mt-3 ml-6">
              Родитель 1
            </p>
            <v-divider v-if="isParentTwoVisible" inset></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.parents.parentOne.lastName"
                  :rules="[rules.required, rules.maxSymbFullName]"
                  label="Фамилия"
                  hint="Введите фамилию"
                  color="#af955c"
                  dense
                  required
                  :error="!studentInfo.parents.parentOne.lastName"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.parents.parentOne.firstName"
                  :rules="[rules.required, rules.maxSymbFullName]"
                  label="Имя"
                  hint="Введите имя"
                  color="#af955c"
                  dense
                  required
                  :error="!studentInfo.parents.parentOne.firstName"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.parents.parentOne.middleName"
                  :rules="[rules.maxSymbMiddleName]"
                  label="Отчество"
                  hint="Введите отчество"
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="6" lg="2" xl="2">
                <v-text-field
                  v-model="studentInfo.parents.parentOne.phone"
                  label="Телефон"
                  hint="Введите телефон"
                  dense
                  v-mask="'+7(###)###-##-##'"
                  color="#af955c"
                  :rules="[rules.required, rules.phone]"
                  required
                  :error="!studentInfo.parents.parentOne.phone || 
                  studentInfo.parents.parentOne.phone == '+7('"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="6" lg="3" xl="2">
                <v-text-field
                  v-model="studentInfo.parents.parentOne.email"
                  label="E-mail"
                  :rules="[rules.email]"
                  hint="Введите e-mail"
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>

            <p v-if="isParentTwoVisible" class="text-overline mt-3 ml-6">
              Родитель 2
            </p>
            <v-divider v-if="isParentTwoVisible" inset></v-divider>

            <v-row
              v-if="isParentTwoVisible"
              class="mt-4"
              justify="center"
              align="center"
            >
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.parents.parentTwo.lastName"
                  label="Фамилия"
                  hint="Введите фамилию"
                  color="#af955c"
                  dense
                  :disabled="isRegistrationSuccess"

                  :rules="
                    !!studentInfo.parents.parentTwo.firstName || 
                    (!!studentInfo.parents.parentTwo.phone ? 
                      studentInfo.parents.parentTwo.phone !== '+7(' : false) ? 
                    [rules.required] : []"
                  
                  :required="!!studentInfo.parents.parentTwo.firstName || 
                    (!!studentInfo.parents.parentTwo.phone ? 
                      studentInfo.parents.parentTwo.phone !== '+7(' : false)"
                  
                  :error="!studentInfo.parents.parentTwo.lastName &&
                    (!!studentInfo.parents.parentTwo.firstName || 
                      (studentInfo.parents.parentTwo.phone ? 
                        studentInfo.parents.parentTwo.phone !== '+7(' : false))"               
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.parents.parentTwo.firstName"
                  label="Имя"
                  hint="Введите имя"
                  color="#af955c"
                  dense
                  :disabled="isRegistrationSuccess"

                  :rules="!!studentInfo.parents.parentTwo.lastName || 
                    (!!studentInfo.parents.parentTwo.phone ? 
                      studentInfo.parents.parentTwo.phone !== '+7(' : false) ? 
                        [rules.required] : []"
                  
                  :required="!!studentInfo.parents.parentTwo.lastName || 
                    (!!studentInfo.parents.parentTwo.phone ? 
                      studentInfo.parents.parentTwo.phone !== '+7(' : false)"
                  
                  :error="!studentInfo.parents.parentTwo.firstName &&
                  (!!studentInfo.parents.parentTwo.lastName || 
                    (!!studentInfo.parents.parentTwo.phone ? 
                      (studentInfo.parents.parentTwo.phone !== '+7(') : false))"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isParentTwoVisible" justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="5" xl="4">
                <v-text-field
                  v-model="studentInfo.parents.parentTwo.middleName"
                  label="Отчество"
                  hint="Введите отчество"
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="6" lg="2" xl="2">
                <v-text-field
                  v-model="studentInfo.parents.parentTwo.phone"
                  label="Телефон"
                  hint="Введите телефон"
                  dense
                  color="#af955c"
                  v-mask="'+7(###)###-##-##'"
                  :disabled="isRegistrationSuccess"

                  :rules="(!!studentInfo.parents.parentTwo.firstName || 
                  !!studentInfo.parents.parentTwo.lastName) ? 
                  [rules.required, rules.phone] : []"
                  
                  :required="!!studentInfo.parents.parentTwo.firstName || 
                  !!studentInfo.parents.parentTwo.lastName"
                  
                  :error="(!studentInfo.parents.parentTwo.phone || 
                  studentInfo.parents.parentTwo.phone === '+7(') &&
                  (!!studentInfo.parents.parentTwo.firstName || 
                  !!studentInfo.parents.parentTwo.lastName)"                 
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="6" lg="3" xl="2">
                <v-text-field
                  v-model="studentInfo.parents.parentTwo.email"
                  label="E-mail"
                  hint="Введите e-mail"
                  :rules="[rules.email]"
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-4" justify="center" align="center">
              <v-btn
                :color="isParentTwoVisible ? '#e31e25' : '#7690c9'"
                class="ma-2"
                text
                dark
                @click="parentButton"
                :disabled="isRegistrationSuccess"
              >
                <v-icon left dark>{{
                  isParentTwoVisible ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                <span v-html="isParentTwoVisible ? 'Удалить родителя' : 'Добавить родителя <br> (Необязательно)'"></span>
              </v-btn>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Данные для входа</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="4" xl="2">
                <v-text-field
                  v-model="studentInfo.login"
                  :rules="[rules.required, rules.minLogin]"
                  label="Логин"
                  hint="Введите логин"
                  color="#af955c"
                  dense
                  required
                  :error="!studentInfo.login"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="3" xl="3">
                <v-text-field
                  v-model="studentInfo.password"
                  color="#af955c"
                  :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.minPassword]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  label="Пароль"
                  hint="Введите пароль"
                  dense
                  required
                  :error="!studentInfo.password"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="3" xl="3">
                <v-text-field
                  v-model="studentInfo.passwordRepeated"
                  color="#af955c"
                  :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    rules.required,
                    rules.passwordMatch(
                      studentInfo.password,
                      studentInfo.passwordRepeated
                    ),
                  ]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  label="Подтверждение пароля"
                  hint="Введите пароль еще раз"
                  required
                  :error="!studentInfo.passwordRepeated"
                  dense
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
      <v-row class="mt-5 mx-4" justify="center" align="center">
        <v-checkbox v-model="isUserAgreedPolicy" color="#af955c" :disabled="isRegistrationSuccess">
          <template v-slot:label>
            <div>
              Продолжая регистрацию, я принимаю условия 
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a target="_blank" href="/docs/agreement.pdf" @click.stop v-on="on">пользовательского соглашения</a>
                </template>
                Обработка персональных данных производится<br/>в соответствии с требованиями 152-ФЗ и 242-ФЗ
              </v-tooltip>
              и даю согласие на обработку персональных данных
            </div>
          </template>
        </v-checkbox>
      </v-row>
      <v-row class="mb-5" justify="center" align="center">
        <v-btn class="mr-5"      
          outlined
          large
          color="#af955c"
          :to="{name: 'SignIn'}">
          На главную
        </v-btn>
        <v-btn
          outlined
          large
          :disabled="!isValid || !isUserAgreedPolicy || isRegistrationSuccess"
          color="#af955c"
          @click="registration"
          >Зарегистрироваться</v-btn
        >
      </v-row>
      <v-snackbar v-model="resultSnackbar" vertical multi-line timeout="-1">
        <span v-html="resultText"></span>
        <template v-slot:action="{ attrs }">
          <v-btn color="error" text v-bind="attrs" @click="() => {resultSnackbar = false; resultText = '';}">
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    <modal-dialog
      :isVisible="isRegistrationSuccess"
      title="Регистрация"
      text="Вы успешно зарегистрировались в личном кабинете. Для продолжения работы, пожалуйста, авторизуйтесь в системе."
      rightButtonText="Авторизоваться"
      :rightButtonAction="routeToMainPage"
    >
    </modal-dialog>
  </div>
</template>

<script>
import axios from "axios";
import _debounce from "debounce";
import CustomModalDialog from '../components/ModalDialog.vue';

import studentInfo from '../model/studentInfo';
import inputRules from '../model/inputRules';
import gendersEnum from '../model/genders';
import gradesEnum from '../model/grades';

export default {
  name: "RegistrationView",
  data: () => ({
    isValid: false,
    isLoading: false,
    dateMenu: false,

    isPasswordVisible: false,
    isParentTwoVisible: false,
    isGeneralEducationVisible: true,
    isProfessionalEducationVisible: false,

    isRegistrationSuccess: false,
  
    genders: gendersEnum,
    
    addressSearch: null,
    awaitingSearch: false,
    addressItems: [],

    resultSnackbar: false,
    resultText: '',

    isUserAgreedPolicy: false,

    studentInfo: JSON.parse(JSON.stringify(studentInfo)),
    generalEducationTypes: [],
    professionalEducationTypes: [],
    grades: gradesEnum,

    emailRetry: '',
    foreignLanguages: [],
    
    rules: {...inputRules},
    token: null
  }),
  components: {
    'modal-dialog': CustomModalDialog
  },
  computed: {
    studentInfoPrepend(){

      if (this.studentInfo.contacts.phone === '+7(')
        this.studentInfo.contacts.phone = null;
      if (this.studentInfo.parents.parentOne.phone === '+7(')
        this.studentInfo.parents.parentOne.phone = null;
      if (this.studentInfo.parents.parentTwo.phone === '+7(')
        this.studentInfo.parents.parentTwo.phone = null;
      
      if (!this.studentInfo.parents.parentTwo.lastName || 
      !this.studentInfo.parents.parentTwo.firstName){
        this.studentInfo.parents.parentTwo.middleName = null;
        this.studentInfo.parents.parentTwo.email = null;
      }     
      if (!this.studentInfo.education.professional.name)
        this.studentInfo.education.professional.name = null;

      this.studentInfo.snils = this.studentInfo.snils ? this.studentInfo.snils.replaceAll(' ', '').replaceAll('-', '') : '';
    },
    studentInfoStringify() {
      this.studentInfo.login = this.studentInfo.login && this.studentInfo.login.trim();

      return JSON.stringify(this.studentInfo);
    },
    debouncedOnChange() {
      return _debounce(this.searchAddress, 700);
    },
    horizontalPaddingValue() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 3;
        case "sm":
          return 5;
        case "md":
          return 8;
        case "lg":
          return 10;
        case "xl":
          return 10;
      }
    },
  },
  methods: {
    setFirstNumAtPhones(){
      this.studentInfo.contacts.phone = '+7(';
      this.studentInfo.parents.parentOne.phone = '+7(';
    },
    getToken(){
      let tokenElements = document.getElementsByName("__RequestVerificationToken");
      if(tokenElements && tokenElements.length > 0)
        this.token = tokenElements[0].value;
    },
    async registration() {
      this.$emit('set-processing', true);
      try {        
        let regData = new FormData();
        
        this.studentInfoPrepend
        
        regData.append('studentInfo', this.studentInfoStringify);
        let response = await axios.post('/api/postRegistration', regData ,{
          headers: {
            'Content-Type': 'multipart/form-data',
            'RequestVerificationToken': this.token
          }
        });
        this.getRegistrationResponse(response.data);
      } 
      catch (error) {
        let data = {
          success: false,
          message: /*error.message*/ "Ошибка регистрации. Пожалуйста, повторите попытку или обратитесь в службу поддержки.",
        };
        this.getRegistrationResponse(data);
      }
    },
    getRegistrationResponse(data) {
      this.resultText = '';
      this.resultSnackbar = false;
    
      this.$emit('set-processing', false);
      
      if(data.success === true) {
        this.isRegistrationSuccess = true;
      }
      else {
        this.isRegistrationSuccess = false;
        this.resultText = data.message;
        this.resultSnackbar = true;
      }
    },
    async getForeignLanguages() {
      try {
        let response = await axios.get("/api/getLanguagesList", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        this.foreignLanguages = response.data;
      } 
      catch (error) {
        this.foreignLanguages = [];
      }
    },
    async getGeneralEducationTypes() {
      try {
        let response = await axios.get(
          "/api/getEducationTypes?kind=" + encodeURIComponent("Общее"),
          {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        this.generalEducationTypes = response.data;
      } 
      catch (error) {
        this.generalEducationTypes = [];
      }
    },
    async getProfessionalEducationTypes() {
      try {
        let response = await axios.get(
          "/api/getEducationTypes?kind=" +
            encodeURIComponent("Профессиональное"),
          {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        this.professionalEducationTypes = response.data;
      } 
      catch (error) {
        this.professionalEducationTypes = [];
      }
    },
    parentButton() {
      this.isParentTwoVisible = !this.isParentTwoVisible;
      
      this.studentInfo.parents.parentTwo.firstName = null;
      this.studentInfo.parents.parentTwo.lastName = null;
      this.studentInfo.parents.parentTwo.middleName = null;
      this.studentInfo.parents.parentTwo.phone = '+7(';
      this.studentInfo.parents.parentTwo.email = null;
    },
    professionalEducationButton() {
      this.isProfessionalEducationVisible = !this.isProfessionalEducationVisible;
      
      this.studentInfo.education.professional.type = null;
      this.studentInfo.education.professional.name = null;
      this.studentInfo.education.professional.grade = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async searchAddress() {
      this.isLoading = true;
      try {
        let response = await axios.get(
          "/api/getAddresses?query=" + encodeURIComponent(this.addressSearch),
          {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        this.addressItems = response.data;
        this.isLoading = false;
      } catch (error) {
        this.addressItems = [];
        this.isLoading = false;
      }
    },
    routeToMainPage() {
      this.$router.push('/').catch(() => {});
    }
  },
  watch: {
    studentInfoStringify: {
      handler(val, oldVal) {
        const newStudentInfo = JSON.parse(val);
        const oldStudentInfo = JSON.parse(oldVal);

        if (newStudentInfo.birthdayDate !== oldStudentInfo.birthdayDate) {
          this.studentInfo.birthdayDateFormated = this.formatDate(
            newStudentInfo.birthdayDate
          );
        }
      },
    },
    addressSearch(val) {
      if (val && val.length >= 3) {
        this.debouncedOnChange();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'HowTo') {
      this.$emit('increment-cache-key');
    } 
    next();
  },
  created() {
    this.getToken();
    this.getForeignLanguages();
    this.getProfessionalEducationTypes();
    this.getGeneralEducationTypes();
  },
  mounted() {
    this.setFirstNumAtPhones();
  },
};
</script>
<style>

.v-btn--fab {
  animation-delay: 1ms;
  animation: shake 8s cubic-bezier(.36,.07,.19,.97) both;
  animation-iteration-count: 5;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  
}

@keyframes shake {
  91%, 99% {
    transform: translate3d(-1px, 0, 0);
  }
  
  92%, 98% {
    transform: translate3d(3px, 0, 0);
  }

  93%, 95%, 97% {
    transform: translate3d(-6px, 0, 0);
  }

  94%, 96% {
    transform: translate3d(5px, 0, 0);
  }
}
</style>